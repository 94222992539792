import { Box, Button, Snackbar, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import MuiAlert from '@mui/material/Alert';

  
function CookiePopup() {

    const cookie = localStorage.getItem("cookie");
    const [open, setOpen] = React.useState(!(cookie === 'Accepted'));
    
    const handleAccept = () => {
        localStorage.setItem("cookie", "Accepted");
        setOpen(false);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  return (
    
    <Snackbar open={open} onClose={handleClose} sx={{ position: 'fixed', left: { sm: '14px', md: '50%', lg: '70%' } , bottom: '14px', maxWidth: '500px' }}>
    <MuiAlert variant="filled" onClose={handleClose} severity="info" icon={false} sx={{ p: "4px 16px", lineHeight: 1.2 }}>
        <Box sx={{display: 'inline-block', p:0, mb: 2 }}>
            This website uses cookies to offer you a better browsing experience. By continuing this website, you agree to the use of cookies.
        </Box>
     
     <Link to="/privacy-policy" relative="path">
            <Button color="inherit" size="small" sx={{textTransform: "capitalize", color: '#fff', border: '1px solid white', m: '0 1em', }}>
                Learn more
            </Button>
        </Link>
        <Button color="inherit" size="small" onClick={handleAccept} sx={{ background: 'white', color: "primary.dark", textTransform: "capitalize", fontSize: '1.1em', fontWeight: 700, p: '.2em 1.5em' }}>
          Accept
        </Button>
    </MuiAlert>
    </Snackbar>
  )
}

export default CookiePopup