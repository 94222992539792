import React from "react";
import { Box, Card, Typography } from "@mui/material";

function Disclaimer() {
  return (
    <section>
      <Box
        sx={{
          m: "0 auto 5em",
          textAlign: "center",
        }}
      >
        <Typography variant="h1" sx={{ color: "primary.dark" }}>
          Terms and Conditions
        </Typography>

        <Typography
          component="p"
          sx={{
            m: "0 auto",
            pt: "2em",
            fontSize: "18px",
            maxWidth: "1000px",
            textAlign: "left",
          }}
        >
          These terms and conditions outline the rules and regulations for the
          use of Freepokies-start’s Games Website.
          <br />
          <br />
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use Freepokies-start Games if you do not agree to
          take all of the terms and conditions stated on this page.
          <br />
          <br />
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: “Client”,
          “You” and “Your” refers to you, the person log on this website and
          compliant to the Company’s terms and conditions. “The Company”,
          “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
          “Parties”, or “Us”, refers to both the Client and ourselves. All terms
          refer to the offer, acceptance and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client’s
          needs in respect of provision of the Company’s stated services, in
          accordance with and subject to, prevailing law of Netherlands. Any use
          of the above terminology or other words in the singular, plural,
          capitalization and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
          <br />
          <br />
          <b>Cookies</b>
          <br />
          We employ the use of cookies. By accessing Freepokies-start Games, you agreed to
          use cookies in agreement with the Freepokies-start Games’s Privacy Policy.
          <br />
          <br />
          Most interactive websites use cookies to let us retrieve the user’s
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
          <br />
          <br />
          <b>License</b>
          <br />
          Unless otherwise stated, Freepokies-start Games and/or its licensors own the
          intellectual property rights for all material on Freepokies-start Games. All
          intellectual property rights are reserved. You may access this from
          Freepokies-start Games for your own personal use subjected to restrictions set in
          these terms and conditions.
          <br />
          <br />
          You must not:
          <br />
          - Republish material from Freepokies-start Games
          <br />
          - Sell, rent or sub-license material from Freepokies-start Games
          <br />
          - Reproduce, duplicate or copy material from Freepokies-start Games
          <br />
          - Redistribute content from Freepokies-start Games
          <br />
          - This Agreement shall begin on the date hereof.
          <br />
          <br />
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website. Hot
          Spins does not filter, edit, publish or review Comments prior to their
          presence on the website. Comments do not reflect the views and
          opinions of Freepokies-start Games,its agents and/or affiliates. Comments reflect
          the views and opinions of the person who post their views and
          opinions. To the extent permitted by applicable laws, Freepokies-start Games shall
          not be liable for the Comments or for any liability, damages or
          expenses caused and/or suffered as a result of any use of and/or
          posting of and/or appearance of the Comments on this website.
          <br />
          <br />
          Freepokies-start Games reserves the right to monitor all Comments and to remove any
          Comments which can be considered inappropriate, offensive or causes
          breach of these Terms and Conditions.
          <br />
          <br />
          You warrant and represent that:
          <br />
          1. You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
          <br />
          2. The Comments do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;
          <br />
          3. The Comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of
          privacy
          <br />
          4. The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful activity.
          <br />
          5. You hereby grant Freepokies-start Games a non-exclusive license to use, reproduce,
          edit and authorize others to use, reproduce and edit any of your
          Comments in any and all forms, formats or media.
          <br />
          <br />
          <b>iFrames</b>
          <br />
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
          <br />
          <br />
          <b>Content Liability</b>
          <br />
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
          <br />
          <br />
          <b>Reservation of Rights</b>
          <br />
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
          <br />
          <br />
          <b>Disclaimer</b>
          <br />
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
          <br />
          <br />
           - limit or exclude our or your liability for death or personal injury;
          <br />
          - limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
          <br />
          - limit any of our or your liabilities in any way that is not permitted
          under applicable law; or exclude any of our or your liabilities that
          may not be excluded under applicable law.
          <br />
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
          <br />
          <br />
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </Typography>
      </Box>
    </section>
  );
}

export default Disclaimer;
