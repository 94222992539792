import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Main from "./pages/Main";
import Policy from "./pages/Policy";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import "./theme/style.css";
import CookiePopup from "./components/CookiePopup";
import Terms from "./pages/Terms";

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Box component="main" sx={{ p: 3 }}>
        <Routes>
          <Route path="/">
            <Route index element={<Main />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy-policy" element={<Policy />} />
          </Route>
        </Routes>
        
      </Box>

      <Snackbar open={true} autoHideDuration={6000}>
        <Alert severity="info" icon={false} sx={{ background: 'rgba(255, 255, 255, 0.6)', fontWeight: 700, p: '0 0.5em'}}>
        No Real Money<br/>Free Online Games 
        </Alert>
      </Snackbar>
      <CookiePopup />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
