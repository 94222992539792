import React from "react";
import { Link } from "react-router-dom";
import { Box, Card, Typography } from "@mui/material";
import img from "../../theme/img/about.jpg";

function About() {
  return (
    <section id="about">
      <Card
        sx={{
          width: "100%",
          minHeight: "250px",
          m: "0 auto",
          borderRadius: "1em",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "0.3px solid #1565c03d",
        }}
        variant="outlined"
      >
        <img
          src={img}
          width="100%"
          height="400px"
          style={{ objectFit: "cover" }}
          alt="company promo"
        />
      </Card>
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{ m: "0 auto", fontWeight: 700, color: "primary.dark" }}
        >
          Featured Games: Exciting Slots and More
        </Typography>
        <Typography
          component="p"
          sx={{ m: "0 auto", pt: "2em", fontSize: "18px", maxWidth: "80%" }}
        >
          Free social casino gaming is transforming our approach to the excitement of gambling by integrating it with the lively realm of social media. These games, designed for fun without monetary risks, are all about the pure pleasure of playing. They welcome individuals from various backgrounds to delve into the thrill of slot machines or the tactical play of card games, all in a safe, risk-free setting. What makes these games truly special is not only the gameplay but also the vibrant communities that emerge around them. Here, players can exchange tips, celebrate each other's victories, and even participate in friendly contests, enriching the gaming experience beyond just playing the games.
<br/><br/>
What's wonderful about these free social casino games is their easy access and welcoming nature. They introduce the world of casino gaming to those hesitant about wagering real money, while still offering the fun and excitement characteristic of casino-style games. These platforms often boast eye-catching graphics and captivating interfaces, creating an immersive and enjoyable experience. For many, they represent the ideal mix of laid-back gaming and socializing, providing a relaxing way to connect with others in an enjoyable, stress-free environment. As the digital landscape continues to evolve, free social casino games are gaining more popularity, establishing a distinct presence in both online gaming and social networking.  </Typography>
      </Box>
    </section>
  );
}

export default About;
