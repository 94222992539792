import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
import banner from "../../theme/img/1.jpg"

function Hero() {
  return (
    <section className="hero">
      <Box sx={{display: { sx:'black', md: 'flex', lg: 'flex'}, justifyContent: 'center', width: '100%', height: '100%'}}>
        <Box>
        <img src={banner} alt="banner with games" style={{maxWidth: '350px'}} />
        </Box>
        <Card
        sx={{
          minHeight: "250px",
          m: "0 auto",
          p: '1em 1.5em',
          borderRadius: "0",
          textAlign: "center",
          display: "flex",
          flexDirection: 'column',
          justifyContent: "flex-start",
          alignItems: "center",
          border: "0.3px solid #1565c03d",
          background: '#3080cf33'
        }}
        variant="outlined"
      >
        <Typography variant="h1" sx={{p: '.5em 0 1em 0', fontWeight: 700, color: "primary.dark", textTransform: 'capitalize' }}>
        Freepokies-start <br/>Free Online Casino Games
        </Typography>
        <Typography component="p" sx={{ maxWidth: '850px', pl:'1em', fontSize: '1em', color: "primary.dark", textAlign: 'left' }}>
        Experience Australia's Premier Free Casino: Engage in Exciting Slots and Poker Without Any Risks!
        </Typography>
        <Button variant="contained" href="#games" sx={{m: "2em 0 0"}} >Play Now</Button>
      </Card>
      </Box>
      
    </section>
  );
}

export default Hero;
