import React from 'react'
import DocumentMeta from "react-document-meta";
import Hero from "../components/contentSections/Hero";
import GamesBlock from '../components/contentSections/GamesBlock';
import About from '../components/contentSections/About';

const meta = {
    title: "Home | Freepokies-start Free Online Games",
    description: "Welcome to Freepokies-start Games, the ultimate destination for those who savor free online gaming with an intriguingly enigmatic twist! ",
    canonical: 'https://freepokies-start.com',
    meta: {
        charset: 'utf-8',
    }
  };

function Main() {
  return (
    <DocumentMeta {...meta}>
        <Hero />
        <GamesBlock />
        <About />
    </DocumentMeta>
  )
}

export default Main