import React from "react";
import Navigation from './Navigation'
import { Box } from "@mui/material";

function Header() {
  return (
    <header>
      <Box sx={{minHeight: '64px'}}>
        <Navigation />
      </Box>
    </header>
  );
}

export default Header;
