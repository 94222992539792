import gargantoonz from "../theme/img/games/gargantoonz.png"
import piggyblitz from "../theme/img/games/piggyblitz.png"
import honeyrush100 from "../theme/img/games/honeyrush100.png"
import boatbonanzacolossalcatch from "../theme/img/games/boatbonanzacolossalcatch.png"
import scalesofdead from "../theme/img/games/scalesofdead.png"
import invadingvegaslaschristmas from "../theme/img/games/invadingvegaslaschristmas.png"
import sweetalchemy100 from "../theme/img/games/sweetalchemy100.png"
import hugolegacy from "../theme/img/games/hugolegacy.png"


const games = [
    {
      title: "GARGANTOONZ",
      description: "Have you heard about Dr Toonz's latest experiment? The renowned doctor has managed to create a small one-eyed Gargantoon, which is the first of its kind. Dr Toonz intends to conduct a series of experiments on this lucky Gargantoon to introduce it to the Reactoonz society. This will result in generating unimaginable wealth and prizes. ",
      img: gargantoonz,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=gargantoonz&lang=en_GB&practice=1&channel=desktop&demo=2`
    },
    {
      title: "PIGGY BLITZ",
      description: "Join a band of plucky pigs as they collect gold coins and deposit them in your piggy bank for sizeable rewards. Be part of the blitz and secure as many gold coins as you can. ",
      img: piggyblitz,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=piggyblitz&lang=en_GB&practice=1&channel=desktop&demo=2`
    },
    {
      title: "HONEY RUSH 100",
      description: "Explore the honey-coated hexagonal grid filled with sugary surprises and one-of-a-kind treasures. Building on the mechanics from the original Honey Rush title, this grid slot doubles down on the sweetness.  ",
      img: honeyrush100,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=honeyrush100&lang=en_GB&practice=1&channel=desktop&demo=2`
    },
    {
      title: "BOAT BONANZA COLOSSAL CATCH",
      description: "It's time to get those lines ready and fix up your nets for the thrilling seafaring slot game, Boat Bonanza Colossal Catch! Drop your bait beneath the waves and reel in a bounty of deep-sea rewards of vibrant marine creatures and big win opportunities.",
      img: boatbonanzacolossalcatch,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=boatbonanza&lang=en_GB&practice=1&channel=desktop&demo=2`
    },
    {
      title: "SCALES OF DEAD",
      description: "This slot follows a lone scout on a journey through the sandy terrain of Ancient Egypt in pursuit of the Golden Snake Tomb, rumoured to contain unparalleled treasure.",
      img: scalesofdead,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=scalesofdead&lang=en_GB&practice=1&channel=desktop&demo=2`
    },
    {
      title: "INVADING VEGAS LAS CHRISTMAS",
      description: "These interstellar invaders are back but this time they’ve had a change of heart. After colliding with Santa’s sleigh, these little green men have decided to put their elf hats on and save Xmas while Saint Nick rests up. So, grab some gifts and load up the sleigh, these extraterrestrial explorers are going to need all the help they can get. Think you’ll be able to save the holiday season? There’s only one way to find out.",
      img: invadingvegaslaschristmas,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=invadingvegas&lang=en_GB&practice=1&channel=desktop&demo=2`
    },
    {
      title: "SWEET ALCHEMY 100",
      description: "Teaming up with Cherry, Berry and Apple, Candy’s passing on her knowledge of sorcery to her prize-making protégés. Our three new witches will be learning the power of teamwork as they rise to sugar-coated stardom! ",
      img: sweetalchemy100,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=sweetalchemy100&lang=en_GB&practice=1&channel=desktop&demo=2`
    },
    {
      title: "HUGO LEGACY",
      description: "Hugo Legacy is the newest instalment in Play’n GO’s revered Hugo series. Bringing back familiar faces from previous slots such as Jean Paul and Don Croco, this title is a fun-filled love letter to the Hugo franchise. ",
      img: hugolegacy,
      src: `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=hugolegacy&lang=en_GB&practice=1&channel=desktop&demo=2`
    }
];
  
  
  
  export default games