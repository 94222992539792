import React from "react";
import ImageCard from "../Card";
import { Box, Typography } from "@mui/material";
import games from '../../data/playngo'



function GamesBlock() {
  return (
    <>
    <Typography
          variant="h3"
          sx={{ m: "2em auto .5em", fontWeight: 700, textAlign: 'center' }}
        >
          Gallery of Top Free Social Casino Games
        </Typography>
        <Typography
          component="p"
          sx={{ m: "0 auto", pt: "0 2em", fontSize: "18px", maxWidth: "80%" }}
        >
          Please note that the games offered on this platform are purely for entertainment purposes and do not involve real money gambling or the chance to win actual prizes. Participation in these games is complimentary and intended for an adult audience. All in-game assets and accomplishments are virtual and hold no value in the real world. Our games are not to be viewed as a means for real money gambling or as a financial investment strategy.
          </Typography>
          
    <Box
    id="games"
      sx={{
        width: "100%",
        maxWidth: '1200px',
        m: "3em auto",
        textAlign: "center",
        boxSizing: "border-box",
      }}
    >
      {games.map((item, index) => (
        <ImageCard
          key={item.title + index}
          {...item}
        />
      ))}
    </Box>
          </>
  );
}

export default GamesBlock;
