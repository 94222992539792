import React from "react";
import DocumentMeta from "react-document-meta";
import DisclaimerSection from "../components/contentSections/Disclaimer";

const meta = {
  title: "Disclaimer | Freepokies-start Free Online Games",
  description: "We are not a gambling operator, and our Services are not intended for real-money gambling purposes. ",
};

function Terms() {
  return (
    <DocumentMeta {...meta}>
      <DisclaimerSection />
    </DocumentMeta>
  );
}

export default Terms;
